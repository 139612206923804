<template>
  <div class="admin">
    <div class="admin_top">
      <div class="admin_top_wrap flex_public">
        <div class="logo flex_public">
          <img src="../../../public/static/img/logo.png" alt />
          <!-- <div class="wrap_text">
            <p>南繁硅谷云</p>
            <p>Nanfan Cloud</p>
          </div>-->
        </div>
        <div class="search">{{adminArr[index_arr]}}</div>
      </div>
    </div>
    <div class="admin_down">
      <div class="admin_down_wrap">
        <div class="admin_down_item" style="height: auto; top:40%;">
          <template v-if="index_arr === 1">
            <admin-login v-if="changeMode" @goRegister="goRegister" @showReset="showReset"></admin-login>
            <!-- <admin-register v-if="!changeMode"  @goRegister="goRegister" @showReset="showReset"></admin-register> -->
          </template>
          <register-nv v-if="index_arr === 0" @goRegister="goRegister" @showReset="showReset"></register-nv>
          <admin-reset v-if="index_arr === 2" @showReset="showReset"></admin-reset>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminLogin from "./login";
import adminRegister from "./register";
import registerNv from "./registerNv";
import adminReset from './adminReset'
export default {
  data() {
    return {
      adminArr: ["欢迎注册", "欢迎登录", "欢迎重置密码"],
      index_arr: 0,
      changeMode: 1,
    };
  },
  components: {
    adminLogin,
    adminRegister,
    registerNv,
    adminReset
  },
  created() {
    this.index_arr = this.$route.query.index * 1;
  },
  methods: {
    goRegister(index) {
      this.index_arr = index
    },
    showReset (index) {
      this.index_arr = index
    }
  }
};
</script>
<style lang="less" scoped>
.admin {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .admin_top {
    width: 100%;
    height: 88px;
    background-color: #cddffe;
    .admin_top_wrap {
      width: 1200px;
      margin: 13px auto;
      .logo {
        width: 250px;
        height: 100%;
        // img {
          // width: 62px;
          // height: 62px;
        // }
        .wrap_text {
          flex: 1;
          p {
            margin: 5px 0;
            text-indent: 15px;
          }
          p:nth-child(1) {
            font-family: MicrosoftYaHei-Bold;
            font-size: 32px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 1px;
            color: #265ae1;
          }
          p:nth-child(2) {
            font-family: MicrosoftYaHei;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #265ae1;
          }
        }
      }
      .search {
        width: 300px;
        height: 100%;
        text-align: right;
      }
    }
  }
  .admin_down {
    flex: 1;
    // background-color: #5695FC;
    background: url("../../assets/images/loginBg.png") no-repeat center;
    background-size: cover;
    .admin_down_wrap {
      position: relative;
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .admin_down_item {
        position: absolute;
        box-sizing: border-box;
        // padding:10px 25px;
        left: 50%;
        top: 50%;
        margin: auto 0;
        width: 690px;
        // max-height: 400px;
        background-color: #fff;
        border-radius: 5px;
        height: 420px;
        margin: -210px 0 0 -345px;
      }
    }
  }
}
</style>
