<template>
  <div class="admin_login">
    <el-container>
      <el-aside width="248px" style="overflow: hidden; height: auto">
        <img src="@/assets/images/login_l.png" alt style="height: -webkit-fill-available"/>
      </el-aside>
      <el-main>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="flex tab">
            <div class="active">账号登录</div>
            <!-- <div  @click="goTo()">账号注册</div> -->
          </div>
          <el-form-item  label prop="phoneNumber" label-width="0">
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-user admin_login_icon"
              v-model.number="ruleForm.phoneNumber"
              autocomplete="off"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label prop="password" label-width="0">
            <el-input
              :show-password="true"
              placeholder="密码"
              type="password"
              prefix-icon="el-icon-lock admin_login_icon"
              v-model="ruleForm.password"
              autocomplete="off"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item  label label-width="0" v-show="UKeyShow">
            <el-input
                placeholder="点击生成枚举设备"
                prefix-icon="el-icon-user admin_login_icon"
                v-model="shebei"
                autocomplete="off"
                readonly
                @click="genEnumDevice"
            ></el-input>
          </el-form-item>

          <el-form-item  label label-width="0" v-show="UKeyShow">
            <el-input
                placeholder="点击生成枚举证书"
                prefix-icon="el-icon-user admin_login_icon"
                v-model="userCert"
                autocomplete="off"
                readonly
                @click="genEnumCert"
            ></el-input>
          </el-form-item>
          <el-form-item  label label-width="0" v-show="UKeyShow">
            <el-input
                placeholder="验证PIN"
                prefix-icon="el-icon-user admin_login_icon"
                v-model="pin"
                autocomplete="off"
                id="user_pin"
            ></el-input>
          </el-form-item>
          <el-form-item  label label-width="0"  v-show="false">
            <el-input
                placeholder="验证PIN"
                prefix-icon="el-icon-user admin_login_icon"
                v-model="ruleForm.userSignCertB64"
                autocomplete="off"
                id="cert_content"
            ></el-input>
          </el-form-item>

          <slider @val="change($event)"></slider>
          <!-- <el-checkbox v-model="checked" class="check_color" @change="record">记住账号密码</el-checkbox> -->
          <el-form-item label-width="0">
            <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            <el-button
                class="admin_login_button"
                @click="getUkey()"
                size="medium"
                type="#d9d9d9"
                v-show="UKeyShow"
            >检测证书</el-button>
            <el-button
              class="admin_login_button_noclick"
              size="medium"
              type="#d9d9d9"
              v-show="showBtn"
            >登录</el-button>
            <el-button
              class="admin_login_button"
              @click="submitForm('ruleForm')"
              size="medium"
              type="#d9d9d9"
              v-show="!showBtn"
            >登录</el-button>
          </el-form-item>
          <!-- <p style="text-align:right;color:#5598fa;cursor:pointer" @click="$emit('showReset',2)">忘记密码</p> -->
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import configUtils from "@/utils/config";
import { Message, Loading } from "element-ui";
import slider from "./slider";

export default {
  components: {
    slider
  },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      UKeyShow: false,
      shebei: "",
      userCert: '',
      pin: '',
      checked: JSON.parse(window.localStorage.getItem("loginrecord") || true),
      ruleForm: {
        userSignCertB64: "",
        signedDataB64: "",
        phoneNumber: "",
        password: ""
      },
      rules: {
        phoneNumber: [
          { required: true, message: "手机号不能为空", trigger: "change" },
          { validator: validatorPhone, trigger: ["blur"] }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "change" }
        ]
      },
      showBtn: true,
      modeIndex: 0
    };
  },
  created() {
    // this.genEnumDevice()
    // this.genEnumCert()
    // this.checked && this.recordAccount();
  },
  watch: {
    // ruleForm: {
    //   handler() {
    //     this.record();
    //   },
    //   deep: true
    // }
  },
  methods: {
    getUkey(){
      let shebei = enumDevice();
      this.shebei = shebei;
      let userCert = getUserList()
      this.userCert = userCert;
    },
    // 枚举设备
    genEnumDevice() {
      let zsp = enumDevice()
      this.shebei = zsp.toString();
    },
    // 获取证书(容器)列表
    genEnumCert() {
      let userlist = getUserList()
      this.userCert = userlist.toString()
    },
    change(data) {
      this.showBtn = data;
    },
    recordAccount() {
      const obj = JSON.parse(window.localStorage.getItem("loginaccount")) || {};
      this.ruleForm = obj;
    },
    record() {
      this.checked
        ? window.localStorage.setItem(
            "loginaccount",
            JSON.stringify(this.ruleForm)
          )
        : window.localStorage.removeItem("loginaccount");
      window.localStorage.setItem("loginrecord", JSON.stringify(this.checked));
    },
    goTo(index) {
      this.$emit("goRegister", 0);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if(this.pin){
          //走u盾
          if (valid) {
            // 加载中
            const loading = Loading.service({
              background: "rgba(0, 0, 0, .2)"
            });
            let pinVefify = verifyUserPin()
            if (pinVefify != 0){
              Message.error("证书pin验证失败");
              return false;
            }
            //导出证书
            let zhengshu = exportUserCert( this.userCert, 1);
            if(!zhengshu){
              Message.error("导出证书失败");
              return false;
            }
            this.ruleForm.userSignCertB64 = zhengshu;

            //通过用户名、密码、证书三个参数，从后台获取随机数
            // 获取api发送请求
            const api = this.$fetchApi.login.api;
            // const api = "http://192.168.3.8:36519/open/v2/register"
            const data = await this.$fetchData.fetchPost(
                this.ruleForm,
                api,
                "json"
            );
            if (data.code === "200" && data.result) {
              // 请求成功
              loading.close(); // 关闭加载
              // 状态保持,存储token
              window.localStorage.setItem(
                  configUtils.baseToken,
                  data.result.access_token,
              );
              window.localStorage.setItem(
                  configUtils.kcCloudToken,
                  data.result.jti,
              );
              window.localStorage.setItem(
                  'loginaccount',
                  JSON.stringify({
                    phoneNumber: data.result.userInfo.username
                  })
              );
              window.localStorage.setItem('accountUseerId', data.result.userInfo.id );
              window.SZHN_NATIVE_BEAR?.setUser(data.result.userInfo.id);
              window.SZHN_FIRE_BEAR?.setUser(data.result.userInfo.id);
              // 改变登录状态
              this.$store.commit("changeLoginStatus", true);
              Message.success("登录成功"); // 提示用户
              setTimeout(() => {
                this.redirect();
              }, 1000);
              const apis = this.$fetchApi.memberInfoSynchronize.api;
              await this.$fetchData.fetchPost({}, apis, "json");
            } else {
              if(data.message == "当前账号需要使用U盾登录") {
                let shebei = enumDevice();
                this.shebei = shebei;
                let userCert = getUserList()
                this.userCert = userCert;
                this.UKeyShow = true;
              }else if (data.message.startsWith("获取随机数成功")){
                // SERVER_RANDOM_SUCCESS("100004", "获取随机数成功"),
                var signRandomData = signData(this.userCert, data.message.replaceAll("获取随机数成功",""));
                this.ruleForm.signedDataB64 = signRandomData;
                const data2 = await this.$fetchData.fetchPost(
                    this.ruleForm,
                    api,
                    "json"
                );
                if (data2.code === "200" && data2.result) {
                  // 请求成功
                  loading.close(); // 关闭加载
                  // 状态保持,存储token
                  window.localStorage.setItem(
                      configUtils.baseToken,
                      data2.result.access_token,
                  );
                  window.localStorage.setItem(
                      configUtils.kcCloudToken,
                      data2.result.jti,
                  );
                  window.localStorage.setItem(
                      'loginaccount',
                      JSON.stringify({
                        phoneNumber: data2.result.userInfo.username
                      })
                  );
                  window.localStorage.setItem('accountUseerId', data2.result.userInfo.id );
                  window.SZHN_NATIVE_BEAR?.setUser(data.result.userInfo.id);
                  window.SZHN_FIRE_BEAR?.setUser(data.result.userInfo.id);
                  // 改变登录状态
                  this.$store.commit("changeLoginStatus", true);
                  Message.success("登录成功"); // 提示用户
                  setTimeout(() => {
                    this.redirect();
                  }, 1000);
                  const apis = this.$fetchApi.memberInfoSynchronize.api;
                  await this.$fetchData.fetchPost({}, apis, "json");
                } else {
                  loading.close(); // 关闭加载
                  Message.error(data.message); // 提示用户
                  return false;
                }
              }
              Message.error(data.message);
              loading.close(); // 关闭加载
              return false;
              // Message.error(data.message); // 提示用户
            }
          } else {
            return false;
          }
        }
        if (valid) {
          // 加载中
          const loading = Loading.service({
            background: "rgba(0, 0, 0, .2)"
          });
          // 获取api发送请求
          const api = this.$fetchApi.login.api;
          // const api = "http://192.168.3.8:36519/open/v2/register"
          const data = await this.$fetchData.fetchPost(
            this.ruleForm,
            api,
            "json"
          );
          if (data.code === "200" && data.result) {
            // 请求成功
            loading.close(); // 关闭加载
            // 状态保持,存储token
            window.localStorage.setItem(
              configUtils.baseToken,
              data.result.access_token,
            );
            window.localStorage.setItem(
              configUtils.kcCloudToken,
              data.result.jti,
            );
            window.localStorage.setItem(
              'loginaccount',
              JSON.stringify({
                phoneNumber: data.result.userInfo.username
              })
            );
            window.localStorage.setItem('accountUseerId', data.result.userInfo.id );
            window.SZHN_NATIVE_BEAR?.setUser(data.result.userInfo.id);
            window.SZHN_FIRE_BEAR?.setUser(data.result.userInfo.id);
            // 改变登录状态
            this.$store.commit("changeLoginStatus", true);
            Message.success("登录成功"); // 提示用户
            setTimeout(() => {
              this.redirect();
            }, 1000);
            const apis = this.$fetchApi.memberInfoSynchronize.api;
            await this.$fetchData.fetchPost({}, apis, "json");
          } else {
            if(data.message == "当前账号需要使用U盾登录") {
              let shebei = enumDevice();
              this.shebei = shebei;
              let userCert = getUserList()
              this.userCert = userCert;
              Message.error(data.message);
              this.UKeyShow = true;
            }
              loading.close(); // 关闭加载
            // Message.error(data.message); // 提示用户
          }
        } else {
          return false;
        }
      });
    },
    redirect() {
      if (this.$route.query.redirect) {
        const redirect = this.$route.query.redirect;
        this.$router.replace(redirect);
      } else {
        this.$router.replace("/");
      }
    }
  }
};
</script>
<style lang="less" scoped>
.admin_login {
  width: 100%;
  /deep/ .el-main {
    padding: 29px;
  }
  /deep/ .admin_login_icon {
    color: #5b6778;
    font-size: 24px;
  }
  /deep/ .el-input__inner {
    padding-left: 51px;
    border: none;
    border-bottom: 1px solid #cacbcc;
    border-radius: 0;
  }
  /deep/ .el-checkbox__inner {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  /deep/ .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
  }
  /deep/ .el-form-item__error {
    padding-left: 51px;
  }
  /deep/ .el-input .el-input__clear {
    font-size: 20px;
  }
  .tab {
    font-size: 18px;
    color: #757575;
    margin-bottom: 20px;
    div {
      cursor: pointer;
    }
    div:first-child {
      padding-right: 30px;
      border-right: 1px solid#cacbcc;
      margin-right: 30px;
    }
    .active {
      color: #5598fa;
    }
  }
  .admin_login_title {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 50px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
  }
  .input_icon {
    width: 120px;
    height: 40px;

    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .admin_login_code {
    display: flex;
    justify-content: space-between;
    .admin_login_flex {
      flex: 1;
    }
  }
  .admin_login_button_noclick {
    width: 100%;
    background-image: linear-gradient(90deg, #4489fc 0%, #265ae0 100%),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    box-shadow: 0px 8px 18px 0px rgba(38, 90, 224, 0.3);
    border-radius: 4px;
    color: #fff;
    height: 51px;
    margin-top: 30px;
    line-height: 51px;
    font-size: 20px;
    padding: 0;
    opacity: 0.5;
  }
  .admin_login_button {
    width: 100%;
    // margin: 20px 0;
    background-image: linear-gradient(90deg, #4489fc 0%, #265ae0 100%),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    box-shadow: 0px 8px 18px 0px rgba(38, 90, 224, 0.3);
    border-radius: 4px;
    color: #fff;
    margin-top: 30px !important;
    height: 51px;
    line-height: 51px;
    font-size: 20px;
    padding: 0;
    margin: 0;
  }
  .admin_login_change {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 27px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #265ae1;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
