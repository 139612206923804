<template>
  <div class="admin_login">
    <el-container>
      <el-aside width="248px">
        <img src="@/assets/images/login_l.png" alt="">
      </el-aside>
      <el-main>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class='flex tab'>
            <div @click="goTo">账号登录</div>
            <div class='active'>账号注册</div>
          </div>
        <el-form-item label prop="phoneNumber" label-width="0">
          <el-input
            placeholder="请输入手机号"
            prefix-icon="el-icon-mobile-phone admin_login_icon"
            v-model.number="ruleForm.phoneNumber"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label prop="verCode" label-width="0">
          <el-input
            class="admin_login_codeInput"
            placeholder="请输入验证码"
            prefix-icon="el-icon-lock admin_login_icon"
            v-model="ruleForm.verCode"
            autocomplete="off"
            clearable
          ></el-input>
          <el-button
            class="admin_codeBtn"
            :disabled="isdisable"
            size="medium"
            type="#5598fa"
            @click="codeBtnEnter()"
          >{{msg}}</el-button>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            class="admin_login_button"
            @click="submitForm('ruleForm')"
            size="medium"
            type="#d9d9d9"
          >登录</el-button>
        </el-form-item>
        <!-- <div class="admin_login_change">
          已经有账号？
          <span @click="goTo">账号登录</span>
        </div> -->
          <!-- <p style="text-align:right;color:#5598fa;cursor:pointer" @click="$emit('showReset',2)">忘记密码</p> -->
      </el-form>
     </el-main>
    </el-container>
  </div>
</template>
<script>
import Vue from "vue";
import configUtils from '@/utils/config';
import { MessageBox, Message, Loading } from "element-ui";
export default {
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          this.isdisable = false;
          callback();
        } else {
          this.isdisable = true;
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      checked: false,
      isdisable: true,
      ruleForm: {
        phoneNumber: "",
        verCode: ""
      },
      rules: {
        phoneNumber: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: validatorPhone, trigger: ["blur", "change"] }
        ],
        verCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ]
      },
      count: '',
      timer: null,
      msg: '发送验证码'
    };
  },
  methods: {
    goTo() {
      this.$emit("goRegister", 1);
    },
    getCode(){
      const TIME_COUNT = 10;
       if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            this.msg = this.count + '秒';
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.msg = '重新发送';
             this.isdisable = false;
          }
        }, 1000);
      }
   },
   async codeBtnEnter() {
     if (this.isdisable) {
       return true;
     }
      const api = this.$fetchApi.codeEnter.api;
      const data = await this.$fetchData.fetchPost(
        {
          operationType: "0",
          phoneNumber: this.ruleForm.phoneNumber
        },
        api,
        "json"
      );
      if (data.code === "200") {
        // Message.error(data.message);
        this.isdisable = true;
        this.getCode();
      }
      this.getCode();
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // 加载中
          const loading = Loading.service({
            background: "rgba(0, 0, 0, .2)"
          });
          // 获取api发送请求
          const api = this.$fetchApi.login.api;
          const data = await this.$fetchData.fetchPost(
            this.ruleForm,
            api,
            "json"
          );
          if (data.code === "200" && data.result) {
            // 请求成功
            loading.close(); // 关闭加载
            // 状态保持,存储token
            window.localStorage.setItem(
              configUtils.baseToken,
              data.result.access_token
            );
            window.localStorage.setItem(
              'loginaccount',
              JSON.stringify({
                phoneNumber: data.result.userInfo.username
              })
            );
            window.localStorage.setItem('accountUseerId', data.result.userInfo.id );
            window.SZHN_NATIVE_BEAR?.setUser(data.result.userInfo.id);
            window.SZHN_FIRE_BEAR?.setUser(data.result.userInfo.id);
            // 改变登录状态
            this.$store.commit("changeLoginStatus", true);
            Message.success("登录成功"); // 提示用户
            setTimeout(() => {
              this.redirect();
            }, 1000);
          } else {
            loading.close(); // 关闭加载
            // Message.error(data.message); // 提示用户
          }
        } else {
          return false;
        }
      });
    },
    redirect() {
      if (this.$route.query.redirect) {
        const redirect = this.$route.query.redirect;
        this.$router.replace(redirect);
      } else {
        this.$router.replace("/");
      }
    }
  }
};
</script>
<style lang="less" scoped>
.admin_login {
  width: 100%;
   /deep/ .el-main{
    padding:29px;
  }
  /deep/ .admin_login_icon{
    color:#5b6778;
    font-size:24px;
  }
  /deep/ .el-input__inner{
     padding-left:51px;
     border:none;
     border-bottom:1px solid #cacbcc;
     border-radius: none;
  }
  /deep/ .el-checkbox__inner{
        border-radius: 50%;
      width: 20px;
      height: 20px
  }
  /deep/ .el-checkbox__inner::after{
    left:7px;
    top: 4px;
  }
  /deep/ .el-form-item__error{
    padding-left:51px;
  }
  /deep/ .el-input .el-input__clear{
    font-size:20px;
  }
  .tab{
    font-size: 18px;
    color: #757575;
    margin-bottom:62px;
    div{
      cursor: pointer;
    }
    div:first-child{
      padding-right:30px;
      border-right: 1px solid#cacbcc;
      margin-right:30px;
    }
    .active{
      color: #5598fa;
    }
  }
  .admin_login_title {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 50px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
  }

  .input_icon {
    width: 120px;
    height: 40px;
    background-color: #ffffff;
    border: solid 1px #cccccc;
    border-radius: 5px;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .admin_login_code {
    display: flex;
    justify-content: space-between;
    .admin_login_flex {
      flex: 1;
    }
  }
  .admin_login_codeInput {
    width: 65%;
  }
  .admin_login_button {
    width: 380px;
    background-image: linear-gradient(90deg,
		#4489fc 0%,
		#265ae0 100%),
    linear-gradient(
      #ffffff,
      #ffffff);
    background-blend-mode: normal,
      normal;
    box-shadow: 0px 8px 18px 0px
      rgba(38, 90, 224, 0.3);
    border-radius: 4px;
    color: #fff;
    margin-top:52px;
    height:51px;
    line-height: 51px;
    font-size:20px;
    padding:0;
  }
  .admin_codeBtn {
    width: 30%;
    float: right;
    color: #5598fa;
    border:1px solid #cacbcc;
  }
  .admin_login_change {
    width: 100%;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 27px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
    span {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #265ae1;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
